export default {
    services: 'servicos',
    searchTickets: 'Procurar Passagens',
    searchTicketsSeo: 'procurar-passagens',
    searchTicketsNow: 'Encontre sua Passagem de Ônibus',
    search:'Procurar',
    from:'Origem',
    to:'Destino',
    departure: 'Ida',
    return:'Volta',
    errorMessageSameStops: 'Origem e Destino não podem ser iguais',
    inputPlaceholder: 'Entre cidade e rodoviária',
    datePlaceholder: 'Selecione data',
    datePlaceholderOpcional: 'Selecione data',
    mandatoryField : 'O campo obrigatório'
}