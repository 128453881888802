export default {
    services: 'servicios',
    searchTickets: 'Buscar Pasajes',
    searchTicketsSeo: 'buscar-pasajes',
    searchTicketsNow: 'Buscá tu pasaje en ómnibus',
    search:'Buscar',
    from:'Origen',
    to:'Destino',
    departure: 'Fecha de ida',
    return:'Fecha de vuelta',
    errorMessageSameStops: 'Origen y Destino no pueden ser iguales',
    inputPlaceholder: 'Ingrese ciudad o terminal',
    datePlaceholder: 'Seleccione fecha',
    datePlaceholderOpcional: 'Opcional',
    mandatoryField : 'Campo obligatorio'
}