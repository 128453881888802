import React from 'react';
import googleAnalytics from 'shared/metrics/google-analytics';
import moment from 'moment';

export default function tracking(WrapperComponent) {
	return class extends React.Component {

		search(params) {
			try {
				/*
				20220406-DesabilitoGoogleAnalitycsViejo
                googleAnalytics.track('trackEventSearchOmnibus', {
					'empresa': "plataforma10",
					'BookingWindows': moment(params.departureDate, 'DD-MM-YYYY').diff(moment().startOf('day'), 'days'),
					'origen': params.departure.text.split('(')[0].trim(),
					'destino': params.destination.text.split('(')[0].trim(),
					'ciudadOrigen': params.departure.text.split('(')[1].replace(')', '').trim(),
					'ciudadDestino': params.destination.text.split('(')[1].replace(')', '').trim(),
					'tipoViaje': params.typeTrip,
					'fechaBusqueda': moment().format('DD-MM-YYYY'),
					'fechaIda': params.departureDate.format('DD-MM-YYYY'),
					'fechaVuelta': params.returnDate ? params.returnDate.format('DD-MM-YYYY') : '',
					'idOrigen': params.departure.id,
					'idDestino': params.destination.id
				});
				*/
			} catch (e) {
			}
		}

		render () {
			const props = {
				tracking: {
					search: this.search
				},
				...this.props
			};
    
			return (
				<WrapperComponent {...props} />
			);
		}
	}
}