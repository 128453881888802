import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { 
	SearchLabel, SearchInput, SearchPicker, SearchButton, SearchContainer, SearchSelectPassenger
} from 'components/shared/searchbox';

import { useApplication } from 'hooks/application';
import useLatestSearches from 'hooks/latestSearches';
import useContent from 'hooks/content';

import urls from 'shared/urls';
import { validateForm, generateUrl, isMexicoOrUSA } from 'shared/services/searchbox';
import { errorCompare ,emptyDate} from 'shared/searchBox/common';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import tracking  from './tracking';
import './translations';
import ft from '../../../../features'
import googleAnalytics from 'shared/metrics/google-analytics';
import cookie from 'shared/cookie';

const features = ft.features()

const Searchbox = ({ tracking, redirectToService, searchQueryString }) => {
	useStyles(classes);
	const history = useHistory();
	const { t } = useTranslation('searchbox');
	const { country, app, typeApplication } = useApplication();
	const { latestSearch, saveSearch } = useLatestSearches();
	const { getBusStops } = useContent();

	const [trip, setTrip] = useState({});
	const [formValid, setFormValid] = useState(false);
	const [showPassengers, setShowPassengers] = useState(false);
	const [stops, setStops] = useState([]);
	const {dateFromServer:minDate} = app;
	const { departure, destination, departureDate, returnDate } = trip;
	const [showGenericMode, setShowGenericMode]=useState(false)
	const [submited ,setSubmited]=useState(false)
	
	useEffect(() => {
		setShowGenericMode(true)
		setShowPassengers(true)
		const getData = async () => {
		  const content = await getBusStops();      
		  setStops(content);
		}

		if(!features.enableElasticSearch) getData();
	}, []);

	useEffect(() => {
		if (latestSearch && JSON.stringify(latestSearch) !== JSON.stringify(trip)) setTrip(latestSearch)
	}, [latestSearch])

	useEffect(() => {		
		const valid = validateForm(trip);
		setFormValid(valid);
	}, [trip]);

	const handlerItemSelected = (item, elem) => {
		const newTrip = {...trip};
		newTrip[elem] = item;
		setTrip(newTrip);
	};


	const handleSearch = event => {
		event.preventDefault();
		setSubmited(true)
		const valid=validateForm(trip)
		if(valid){
			trackingEventSearchResult()
			tracking.search({
				departureDate: departureDate,
				returnDate: returnDate,
				departure: departure,
				destination: destination,
				typeTrip: returnDate ? 'idayvuelta' : 'ida'
			});
			saveSearch(trip);
			const baseUrl = generateUrl(trip, t('services'), t('searchTicketsSeo'), searchQueryString);
			features.enableServices && !redirectToService
				? history.push(baseUrl)
				: window.location = `${urls.domain}.${country.url}${baseUrl}`;
	} 
	};

	const trackingEventSearchResult = () => {
		const origen = departure.nameSeo.replace(/-/gi, "_").replace(/(\_)|(\-)/g, " ").toLowerCase()
		const destino = destination.nameSeo.replace(/-/gi, "_").replace(/(\_)|(\-)/g, " ").toLowerCase()
		const sourceValue = cookie.get('source')
		const eventP10Search ={
			'vertical': 'Omnibus',
			'event': 'p10Search',
			'source': sourceValue,
			'ecommerce': {
				'departureDate':departureDate.format('DD-MM-YYYY'),
				'destino':destino,
				'returnDate':returnDate ? returnDate.format('DD-MM-YYYY') : null,
				'origen':origen
			}
		}

	
		googleAnalytics.TrackForGA4(eventP10Search,true)
	}
	const handlerPassengersChange = passengers => setTrip({ ...trip, ...passengers });

	return (
		<SearchContainer onSubmit={handleSearch} title={typeApplication.isOW ? null : (t('searchTicketsNow')) }>
			<div className={typeApplication.isOW ? classes.owContainer : classes.container }>
				<div className={classes.inputs}>
					<SearchLabel
						label={t('from')} 
						htmlFor='departure' 
						iconName='map-marker-alt'
					/>
					<SearchInput 
						field='departure' 
						id='departure' 
						placeholder={t('inputPlaceholder')} 
						onBlurSelected
						onSelectItem={handlerItemSelected} 
						stops={stops}
						initValue={departure}
						disableUnderline
						usesElastic={true}
						err={departure==null && submited==true?{message:t('mandatoryField'),status:true}:errorCompare(trip, t('errorMessageSameStops'))}

					/>
				</div>

				<div className={classes.inputs}>
					<SearchLabel
						label={t('to')} 
						htmlFor='destination' 
						iconName='map-marker-alt'
					/>
					<SearchInput 
						field='destination' 
						id='destination' 
						placeholder={t('inputPlaceholder')} 
						onBlurSelected
						onSelectItem={handlerItemSelected} 
						stops={stops}
						initValue={destination}
						disableUnderline
						err={destination==null && submited==true?{message:t('mandatoryField'),status:true}:false}
						usesElastic={true}
					/>
				</div>

				<div className={`${classes.inputs} ${classes.inputDate}`}>
					<SearchLabel
						label={t('departure')} 
						htmlFor='departureDate' 
						iconName='calendar-alt' 
					/>
					<SearchPicker 
						id='departureDate' 
						field='departureDate' 
						placeholder={t('datePlaceholder')}
						onSelectItem={handlerItemSelected} 
						initValue={departureDate} 
						compareValue={destination} 
						minDate={minDate}
						err={submited?emptyDate(departureDate,t('mandatoryField')):false}
					/>
				</div>

				<div className={`${classes.inputs} ${classes.inputDate}`}>
					<SearchLabel
						label={t('return')} 
						htmlFor='returnDate' 
						iconName='calendar-alt'
						optional={true}
					/>
					<SearchPicker 
						id='returnDate' 
						field='returnDate' 
						placeholder={t('datePlaceholderOpcional')}
						onSelectItem={handlerItemSelected} 
						minDate={minDate} 
						initValue={returnDate} 
					/>
				</div>
				{
					showPassengers &&
						<div className={`${classes.inputs} ${classes.inputDate}`}>
								<SearchSelectPassenger 
									customClasses={showGenericMode? '':{ selectorContainer: classes.selectorContainer }}
									maxValue={7} 
									showGenericMode={showGenericMode}
									onChange={handlerPassengersChange} 
								/>
						</div>
				}

			</div>

			<SearchButton id='searchButton'  type='submit'>
				{ t('searchTickets') }
			</SearchButton>
		</SearchContainer>
	);
}

export default tracking(Searchbox);