import { useState, useEffect } from 'react';

import useLocalStorage from 'hooks/localStorage';

import moment from 'moment';

const NAME_KEY_STORAGE = 'latestSearches';

const useLatestSearches = () => {
  const { hasLocalStorage, getValueFromLocalStorage } = useLocalStorage();
  const [searches, setSearches] = useState([]);
  const [latestSearch, setLatestSearch] = useState();

  useEffect(() => {
    const searches = getValueFromLocalStorage(NAME_KEY_STORAGE, []);
    setSearches(searches);
  }, [])

  useEffect(() => {
    const setReturnDate = (departureDate, returnDate, format) => 
      moment(returnDate, format) > moment(departureDate, format) && moment(departureDate) > moment() 
        ? moment(returnDate, format) 
        : null;

    const getLastSearch = () => {
      if (searches.length) {
        const lastSearch = searches.pop();
        const format = 'YYYY-MM-DD';
        const departureDate = moment(lastSearch.departureDate, format);
        
        setLatestSearch({
          departure: lastSearch.departure,
          destination: lastSearch.destination,
          departureDate: departureDate >= moment()
            ? departureDate
            : null,
          returnDate: setReturnDate(departureDate, lastSearch.returnDate, format)
        });
      }
    }

    getLastSearch();
  }, [searches]);

  const saveSearch = search => {
    if (!hasLocalStorage()) return;
    const lastSearch = [];

    lastSearch.push({
      departure: search.departure,
      destination: search.destination,
      departureDate: search.departureDate,
      returnDate: search.returnDate
    });

    localStorage.setItem(NAME_KEY_STORAGE, JSON.stringify(lastSearch));
    setSearches(lastSearch);
  }

  return { searches, latestSearch, saveSearch };
}

export default useLatestSearches;