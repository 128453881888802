import React, { useState, useEffect } from 'react'

export default ({ children }) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, [])

    return isMounted ? <> { children } </> : null
}
