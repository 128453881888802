import i18next  from 'i18next';

import es from './es';
import pt from './pt';
import en from './en';
import pe from './pe';
import py from './py';

i18next.addResourceBundle('es-AR', 'searchbox', es);
i18next.addResourceBundle('es-PE', 'searchbox', pe);
i18next.addResourceBundle('en-US', 'searchbox', en);
i18next.addResourceBundle('pt-BR', 'searchbox', pt);
i18next.addResourceBundle('es-PY', 'searchbox', py);